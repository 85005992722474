@page {
	margin: 15mm;
}

/* Print header */
@media print {
	.print-header > img {
		display: block;
		height: auto;
		width: 120pt;
	}

	.print-title {
		color: #aaa;
		left: 0;
		position: fixed;
		top: 0;
		text-align: center;
		width: 100%;
	}
}


/* Print body */
@media print {
	.print-body .form-group {
		overflow: hidden;
	}

	.print-body .form-group > label {
		float: left;
		width: 25%;
	}

	.print-body .form-group > .d-none.d-print-block {
		float: right;
		width: 75%;
	}
}


/* Print footer */
@media print {}


.print-answer {
	line-height: 1.4em;
}
.print-answer * {
	margin: 0;
}


@media print {
	.container {
		padding-top: 15mm !important;
	}
}
